import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";

export default function DeleteTimeSheetLog({ timeLog, onTimeSheetLogDeleted, updateSheet }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    onTimeSheetLogDeleted();
    setOpen(false);
  };

  const handleDelete = () => {
    const endpoint = `/api/Timesheet/deleteTimeLog/${timeLog.id}`;

    axios
      .delete(endpoint)
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data) {
            alert(error.response.data);
            handleClose();
        } else {
            alert("Error deleting time log. Please try again.");
            handleClose();
        }
      })
      .finally(() => {
        updateSheet();
      })
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Time Log</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this log?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}