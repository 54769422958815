import React, { useState } from "react";
import { Grid, Box, Stack, Typography, Button, Modal, TextField, Dialog, DialogTitle, DialogContent, DialogActions }from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '40%',
  overflow: 'scroll',
  overflowX: "hidden",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function HolidayInputForm( props ) {

  const [holidayName, setHolidayName] = useState("");
  const [holidayDate, setHolidayDate] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const dialogTitle = "Please Enter All Required Fields";
  const dialogText = "";
  const [loading, setLoading] = useState(false);

  const handleCleanUp = () => {
    setHolidayName("");
    setHolidayDate(null);
  };

  const handleSubmitRequest = () => {
    setLoading(true);

    if (holidayName && holidayDate) {
      const newHoliday = {
        holiday1: holidayName,
        holidayDate: holidayDate ? holidayDate.toISOString().substring(0, 10) : null,
      }

      axios
        .post("/api/TimeSheet/addHoliday", newHoliday)
        .then(() => {
        })
        .catch((error) => {
            console.error(error);
            alert(error.response?.data || "Error creating Holiday. Please try again.");
        })
        .finally(() => {
            props.handleHolidayModalClose();
            props.updateHolidayTable();
            handleCleanUp();
            setLoading(false);
        });
    } else {
      setOpenDialog(true);
      setLoading(false);
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              {dialogText}
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                  Close
              </Button>
          </DialogActions>
      </Dialog>

      <Modal
          open={props.openHolidayModal}
          onClose={props.handleHolidayModalClose}
      >
          <Box sx={style}>
              <Button
                  variant="outlined"
                  onClick={props.handleHolidayModalClose}
                  style={{ position: "absolute", top: "10px", right: "10px" }}
              >
                  X
              </Button>

              <Typography
                  variant="h6"
                  component="h2"
                  style={{ paddingBottom: "20px" }}
              >
                  Enter Holiday Information
              </Typography>

              <Stack direction="row" width={"100%"} marginBottom={"10px"} >
                  <Stack>
                      <Stack width={"100%"}>
                        <TextField
                          label="Holiday Title"
                          value={holidayName}
                          onChange={event => setHolidayName(event.target.value)}
                        />
                      </Stack>
                  </Stack>
                  
                  <Stack width={"15%"}/>

                  <Stack widith={"100%"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Holiday Date"
                        value={holidayDate}
                        onChange={setHolidayDate}
                      />
                    </LocalizationProvider>
                  </Stack>
              </Stack>

              <Grid
                  container
                  justifyContent="flex-end"
              >
                  <Button
                      style={{ color: "green" }}
                      onClick={handleSubmitRequest}
                      disabled={loading}
                  >
                      Submit
                  </Button>
              </Grid>
          </Box>
      </Modal>
    </>
  );
}