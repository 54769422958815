import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import MuiAlert from "@mui/material/Alert";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import EditNetworkInfo from './EditNetworkInfo';

export default function NetworkInfoTable({ id }) {
  const [rowId, setRowId] = useState(id);
  const [networkInfo, setNetworkInfo] = useState({});
  const [networkType, setNetworkType] = useState('');
  const [lanDhcp, setLanDhcp] = useState(false);
  const [wanDhcp, setWanDhcp] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const containerBorderStyle = { border: '2px solid #BEBEBE' };
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
  
    const formattedNumber = phoneNumber.replace(/\D+/g, ""); // Remove non-numeric characters
    const areaCode = formattedNumber.slice(0, 3);
    const firstThree = formattedNumber.slice(3, 6);
    const lastFour = formattedNumber.slice(6);
  
    return `(${areaCode}) ${firstThree}-${lastFour}`;
  };

  const fetchData = async () => {
    try {
      const networkInfoEndpoint = `api/NetworkInfo/networkInfoFromDeviceId/${rowId}`;
      const response = await axios.get(networkInfoEndpoint);
      setNetworkInfo(response.data);
      setNetworkType(response.data.networkType);
      setLanDhcp(response.data.lanDhcp !== null ? response.data.lanDhcp : false);
      setWanDhcp(response.data.wanDhcp !== null ? response.data.wanDhcp : false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("Error retrieving data", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [rowId]);

  const handleCheckboxChange = async (event) => {
    const { name, checked } = event.target;
    
    if (name === 'lanDhcp') setLanDhcp(checked);
    if (name === 'wanDhcp') setWanDhcp(checked);

    const newNetworkInfo = {
      ...networkInfo,
      lanDhcp: name === 'lanDhcp' ? checked : lanDhcp,
      wanDhcp: name === 'wanDhcp' ? checked : wanDhcp
    };

    try {
      setIsLoading(true);

      await axios.put("api/NetworkInfo/updateNetworkInfo", newNetworkInfo);

      setNetworkInfo((prevInfo) => ({
        ...prevInfo,
        [name]: checked,
      }));

      setSnackbarOpen(true);
    }
    catch (error) {
      console.error("Error updating network info:", error);
      alert(JSON.stringify(error.response?.data) || "Error saving network info. Please try again.");
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TableContainer component={Paper} style={containerBorderStyle}>
        <Table
          style={{ borderCollapse: 'collapse', minHeight: '500px', maxHeight: '500px' }} 
          sx={{
            '& .MuiTableCell-root': {
              padding: '4px', 
              fontSize: '0.875rem', 
              backgroundColor: 'inherit',
            },
            '& .MuiTableCell-head': {
              fontWeight: 'bold',
              backgroundColor: 'inherit',
            },
            '& .MuiTableRow-root': {
              height: '24px',
            },
            '& th, & td': {
              whiteSpace: 'nowrap', 
              textAlign: 'center', 
            },
            '&:hover': { backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#252525' },
            '& .no-border': {
              border: 'none', 
            },
            '& .bottom-border-only': {
              borderTop: 'none',
              borderBottom: theme.palette.mode === 'light' ? '1px solid #D2D2D2' : '1px solid #4D4D4D'
            }
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} align="center" style={{ fontWeight: 'bold' }}>
                <Box display="inline-flex" alignItems="center" justifyContent="space-around" width="auto" marginLeft='15%'>
                  <Typography variant='h6' gutterBottom style={{ fontWeight: 'bold', fontSize: '27px' }}>
                    Network Information
                  </Typography>
                  {!isLoading && networkInfo && (
                    <Box ml={2}>
                      <EditNetworkInfo lanDhcp={lanDhcp} wanDhcp={wanDhcp} netInfo={networkInfo} netType={networkType} onSave={fetchData} />
                    </Box>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>
                <FormControlLabel
                  value="lanDhcp"
                  control={
                    <Checkbox 
                      sx={{ transform: 'scale(0.7)', marginLeft: '-30px' }} 
                      checked={lanDhcp}
                      onChange={handleCheckboxChange}
                      name='lanDhcp'
                    />
                  }
                  label="DHCP"
                  labelPlacement="bottom"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.5rem', marginLeft: '-30px' } }}
                />
                LAN
              </TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>
                <FormControlLabel
                  value="wanDhcp"
                  control={
                    <Checkbox 
                      sx={{ transform: 'scale(0.7)', marginLeft: '-30px' }}
                      checked={wanDhcp}
                      onChange={handleCheckboxChange}
                      name='wanDhcp'
                    />
                  }
                  label="DHCP"
                  labelPlacement="bottom"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.5rem', marginLeft: '-30px' } }}
                />
                WAN
              </TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Cellular</TableCell>
            </TableRow>
          </TableBody>
          
          <TableBody>
            <TableRow>
              <TableCell>IP:</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.lanIpAddress}</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.wanIpAddress}</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.cellIpAddress}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Subnet Mask:</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.lanSubnetMask}</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.wanSubnetMask}</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.cellSubnetMask}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gateway:</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.lanGateway}</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.wanGateway}</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.cellGateway}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DNS 1:</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.lanDns1}</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.wanDns1}</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.cellDns1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DNS 2:</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.lanDns2}</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.wanDns2}</TableCell>
              <TableCell colSpan={1} align="center">{networkInfo.cellDns2}</TableCell>
            </TableRow>
            <TableRow className="no-border">
              <TableCell colSpan={4} className="no-border"></TableCell>
            </TableRow>
            <TableRow className="bottom-border-only">
              <TableCell colSpan={4} className="no-border"></TableCell>
            </TableRow>
            <TableRow className="bottom-border-only">
              <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>SIM Carrier</TableCell>
              <TableCell colSpan={1} style={{ fontWeight: 'bold' }}>SIM Phone</TableCell>
              <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>IMEI</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SIM 1: {networkInfo.sim1Carrier}</TableCell>
              <TableCell colSpan={1} align="center">{formatPhoneNumber(networkInfo.sim1Phone)}</TableCell>
              <TableCell colSpan={2} align="center">{networkInfo.sim1Imei}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SIM 2: {networkInfo.sim2Carrier}</TableCell>
              <TableCell colSpan={1} align="center">{formatPhoneNumber(networkInfo.sim2Phone)}</TableCell>
              <TableCell colSpan={2} align="center">{networkInfo.sim2Imei}</TableCell>
            </TableRow>
            <TableRow className="no-border">
              <TableCell colSpan={4} className="no-border"></TableCell>
            </TableRow>
            <TableRow className="bottom-border-only">
              <TableCell colSpan={4} className="no-border"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Router:</TableCell>
              <TableCell colSpan={3} align="center">{networkInfo.router}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DDNS:</TableCell>
              <TableCell colSpan={3} align="center">{networkInfo.ddns}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>MAC Address:</TableCell>
              <TableCell colSpan={3} align="center">{networkInfo.macAddress}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Serial Number:</TableCell>
              <TableCell colSpan={3} align="center">{networkInfo.serialNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Default Password:</TableCell>
              <TableCell colSpan={3} align="center">{networkInfo.defaultPassword}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Network Info updated!
        </MuiAlert>
      </Snackbar>
    </>
  );
}


