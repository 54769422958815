import React, { useState, useMemo, useEffect } from "react";
import { Box, Button, TableContainer } from '@mui/material';
import { systemsIntegrationAxios as axios } from "../../utils/AxiosUtility";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

export default function DeviceInfoMainTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [sprocExecuted, setSprocExecuted] = useState(false);
    const [templateSprocExecuted, setTemplateSprocExecuted] = useState(false);
    const [allProductConfigs, setAllProductConfigs] = useState([]);
    const [allSiteData, setAllSiteData] = useState([]);
    const [productConfigTableData, setProductConfigTableData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const executeSproc = () => {
            try {
                const storedProcEndpoint = `api/ProductSummary/updateProductSummary`;
                axios.get(storedProcEndpoint)
                    .then((response) => {
                        console.log(response.data);
                    });

                setSprocExecuted(true);
            }
            catch (error) {
                console.error("Error executing the stored procedure: ", error);
            }
        }

        executeSproc();
    }, []);

    useEffect(() => {
        const executeTemplateSproc = () => {
            try {
                const storedProcTemplateEndpoint = `api/ProductSummary/updateTemplateNumber`;
                axios.get(storedProcTemplateEndpoint)
                    .then((response) => {
                        console.log(response.data);
                    });

                setTemplateSprocExecuted(true);
            }
            catch (error) {
                console.error("Error executing the stored procedure: ", error);
            }
        }

        executeTemplateSproc();
    }, []);

    useEffect(() => {
        const fetchAllProductInfo = async () => {
            try {
                const prodSummaryEndpoint = `api/ProductSummary/allProductSummaries`;
                axios.get(prodSummaryEndpoint)
                    .then((response) => {
                        setAllProductConfigs(Array.isArray(response.data) ? response.data : [response.data]);
                    });
            }
            catch (error) {
                console.error("Error fetcing product info: ", error);
            }
        }

        fetchAllProductInfo();
    }, [sprocExecuted]);

    useEffect(() => {
        const fetchAllSiteInfo = async () => {
            try {
                const prodSummaryEndpoint = `api/SiteLookup/getAllSiteInfo`;
                axios.get(prodSummaryEndpoint)
                    .then((response) => {
                        setAllSiteData(Array.isArray(response.data) ? response.data : [response.data]);
                    });
            }
            catch (error) {
                console.error("Error fetcing product info: ", error);
            }
        }

        fetchAllSiteInfo();
    }, [sprocExecuted]);

    useEffect(() => {
        const transformedData = allProductConfigs.map(item => {
            const matchingSiteData = allSiteData.find(site => site.projectId === item.projectId);

            return {
                productConfigurationId: item.productConfigurationId,
                productId: item.productId,
                projectId: item.projectId || null,
                productType: item.productType,
                productName: item.productName || '',
                customerMaster: item.customerMaster || '',
                siteName: item.siteName || '',
                siteState: item.siteState || '',
                csCountry: matchingSiteData ? matchingSiteData.csCountry : '',
                riserCount: item.riserCount || '',
                thermalCameraCount: item.thermalCameraCount || '',
                flameDetectorCount: item.flameDetectorCount || '',
                smokeDetectorCount: item.smokeDetectorCount || '',
                centralStation: item.centralStation || '',
            };
        });

        setProductConfigTableData(transformedData);
    }, [allProductConfigs, allSiteData]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'productName',
                header: 'Product Name',
                Cell: ({ cell, row }) => (
                    <Button onClick={() => {
                        const pcId = row.original.productConfigurationId; 
                        const prodType = row.original.productType; 
                        navigate(`/Device/MasterDeviceInfo?productConfigId=${pcId}&prodType=${prodType}`);
                    }}>
                        {cell.getValue()}
                    </Button>
                ),
            },
            { accessorKey: 'productType', header: 'Product Type' },
            { accessorKey: 'customerMaster', header: 'Master Customer', size: 250 },
            { accessorKey: 'siteName', header: 'Site Name', size: 250 },
            { accessorKey: 'siteState', header: 'Site State', size: 175 },
            { accessorKey: 'csCountry', header: 'Country', size: 150},
            { accessorKey: 'riserCount', header: 'Riser Count' },
            { accessorKey: 'thermalCameraCount', header: 'Thermal Camera Count' },
            { accessorKey: 'flameDetectorCount', header: 'Flame Detector Count' },
            { accessorKey: 'smokeDetectorCount', header: 'Smoke Detector Count' },
            { accessorKey: 'centralStation', header: 'Central Station'},
        ],
        [navigate]
    );

    const data = useMemo(() => {
        if (!searchQuery) return productConfigTableData;
        return productConfigTableData.filter((row) =>
            Object.values(row).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, productConfigTableData]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnOrdering: true,
        enableStickyHeader: true,
        enableColumnResizing: true,
        paginationDisplayMode: 'pages',
        onGlobalFilterChange: setGlobalFilter,
        layoutMode: 'grid',
        initialState: {
            density: 'compact',
            pagination: { pageSize: 100, pageIndex: 0 },
        },
        state: { globalFilter },
        muiPaginationProps: {
            shape: 'rounded',
            rowsPerPageOptions: [50, 100, 200],
            showFirstButton: true,
            showLastButton: true,
        },
        muiTableContainerProps: { sx: { maxHeight: '70vh' } },
    });


    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            minHeight="100vh" 
            style={{ marginLeft: 'auto', marginRight: 'auto', padding: '0 16px' }}
        >
            <TableContainer style={{ maxHeight: '90vh', overflow: 'auto' }}>
                <MaterialReactTable table={table} />
            </TableContainer>
        </Box>
    );
};


