import React, { useState, useEffect } from "react";
import { Grid, Fade, Modal, Button} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import DeleteHoliday from "./DeleteHoliday";

export default function HolidayTable( props ) {

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [holiday, setHoliday] = useState();
  const [loading, setLoading] = useState(false);

  const handleOpenDeleteModal = (holiday) => {
    setHoliday(holiday);
    setOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const fetchData = () => {
    setLoading(true);
    const endpoint = 'api/TimeSheet/getHoliday';
    axios.get(endpoint)
      .then((response) => {
        loadRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [props.holidayListener]);   

  const loadRows = (holiday) => {

    let newRows = [];
    if (holiday.length > 0) {
      holiday.forEach(hd => {
        const offsetDate = (date) => {
          return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
        }
        newRows.push({
          id: hd.holidayId,
          holiday: hd.holiday1 ? hd.holiday1 : "-",
          date: hd.holidayDate ? offsetDate(new Date(hd.holidayDate)).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }) : "-",
        })
      })
    }
    setRows(newRows);
  }

  const renderEditButton = (params) => {
      return (
        <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
                handleOpenDeleteModal(params.row)
            }}
        >
            Delete
        </Button>
      )
  }

  const columns = [
    {
      width: 100,
      renderCell: renderEditButton,
      disableClickEventBubbling: true,
    },
    { field: "holiday", headerName: "Holiday", width: 150 },
    { field: "date", headerName: "Date", width: 100 },
  ];

  return (
    <Grid>
      <DataGrid rows={rows} columns={columns} 
        initialState={{ pagination: { paginationModel: { pageSize: 25 } }, columns: {columnVisibilityModel: {}} } }
        pageSizeOptions={[10, 25, 100]}
        disableRowSelectionOnClick
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
          },
          "& .MuiDataGrid-row:hover": {
              cursor: "pointer"
          },
          overflowY: 'scroll', 
          height: 445,
          width: "100"
        }}
        state={{
          showSkeletons: loading,
        }}
      />
      <Modal
        open={open}
        onClose={handleCloseDeleteModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <div>
            <DeleteHoliday holiday={holiday} handleClose={handleCloseDeleteModal} updateHolidays={props.updateHolidayTable} />
          </div>
        </Fade>
      </Modal>
    </Grid>
  );
};