import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import { useMsal } from "@azure/msal-react";

export default function EditServiceLogNote({ note, onUpdateNote, openSnackbar }) {
  const [editedNote, setEditedNote] = useState(note?.note || "");
  const { instance } = useMsal();
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [markCompleted, setMarkCompleted] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount) {
      setName(activeAccount.name.split(' ')[0]);
    } else {
      setName(null);
    }
  }, [activeAccount]);

  const handleSave = () => {
    if (markCompleted) {
      setShowConfirmationDialog(true);
    }
    else {
      executeSave();
    }
  };

  const executeSave = () => {
    setLoading(true);

    axios
      .put('api/DeviceSheetNote/updateDeviceSheetNote', {
        logId: note.logId,
        username: name,
        note: editedNote,
        logDate: new Date(new Date().getTime()),
        isComplete: markCompleted ? true : false,
      })
      .then(() => {
        onUpdateNote(); 
        openSnackbar();
      })
      .catch((error) => {
        console.log(error);
        alert("Error updating service log note. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        setShowConfirmationDialog(false);
      });
  };

  const handleDialogueClose = (confirmed) => {
    setShowConfirmationDialog(false);
    if (confirmed) {
      executeSave();
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Button
          variant="outlined"
          onClick={onUpdateNote}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          X
        </Button>

        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
          Edit Note
        </Typography>

        <TextField
          multiline
          label="Enter message"
          value={editedNote}
          onChange={(e) => setEditedNote(e.target.value)}
          fullWidth
          style={{ marginBottom: "20px" }}
        />

        <FormControlLabel 
          control={<Checkbox checked={markCompleted} onChange={(e) => setMarkCompleted(e.target.checked)} />}
          label="Mark Complete"
        />

        <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
          {loading && <CircularProgress />}

          <Button
            style={{ color: "green" }}
            onClick={handleSave}
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </Box>

      <Dialog open={showConfirmationDialog} onClose={() => handleDialogueClose(false)}>
        <DialogTitle>Confirm Completion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to mark this as complete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogueClose(false)} color="primary">
            No
          </Button>
          <Button onClick={() => handleDialogueClose(true)} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

