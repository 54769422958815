import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Card, CardMedia } from "@mui/material";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';

export default function CurrentDesignDisplay(props) {
  const [currentDesign, setCurrentDesign] = useState("");
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  if (props.fetchDesignData || props.obSubmit) {
    const endpoint = `api/Onboarding/latestDesign/${props.projectId}`;

    axios
      .get(endpoint)
      .then((response) => {
        setCurrentDesign(response.data);
        props.onDataFetched();
      })
      .catch((error) => {
        console.error(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching latest design.");
        }
      });
  }

  return (
    <Card style={{ height: '100%', width: "100%", padding: '30px' }}>
      <Typography
        variant="h6"
        align="center"
        style={{ margin: "5px 0", height: "fit-content" }}
      >
        Current Design
      </Typography>
      <div style={{ position: "relative", height: "100%"  }}>
        <CardMedia
          component={
            [".jpg", ".jpeg", ".png", ".gif", ".bmp"].includes(
              currentDesign.extensionType
            )
              ? "img"
              : "iframe"
          }
          alt="File"
          src={currentDesign.documentLink}
          title="Current Design"
          style={{ objectFit: "contain", height: "400px" }}
        />
      </div>
    </Card>
  );
}
