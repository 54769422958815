import { useEffect, useState } from 'react';
import { Avatar, Box, Button, Tooltip, Skeleton, Modal, Fade, Snackbar, Typography, useTheme } from '@mui/material';
import frdarklogo from "../../../res/FRLogo.png";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import MuiAlert from '@mui/material/Alert';
import { MaterialReactTable } from 'material-react-table';
import AddServiceLogNote from './AddServiceLogNote';
import EditServiceLogNote from './EditServiceLogNote';

export default function ServiceLogTable({ id }) {
    const [serviceLogData, setServiceLogData] = useState([]);
    const [filteredNotes, setFilteredNotes] = useState([]);
    const [showingAll, setShowingAll] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [noteToEdit, setNoteToEdit] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);
    const theme = useTheme(); 

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        const endpoint = `api/DeviceSheetNote/deviceLevelServiceLog/${id}`;

        axios.get(endpoint)
            .then((response) => {
                const sortedData = response.data.sort((a, b) => new Date(b.logDate) - new Date(a.logDate));
                const filteredData = sortedData.filter(note => note.isComplete !== true);
                setServiceLogData(sortedData);
                setFilteredNotes(filteredData);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                if (error.message) {
                    alert(error.message);
                } else {
                    alert("Error fetching Service log.");
                }
            });
    }

    const handleRowDoubleClick = (note) => {
        setNoteToEdit(note);
        setIsEditModalOpen(true);
    }

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        fetchData();
    }

    const openSnackbar = () => {
        setSnackbarOpen(true);
    }

    const offsetDate = (date) => {
        return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
    }

    const columns = [
        {
            accessorKey: 'username',
            header: 'User',
            Cell: ({ cell }) => (
                <Tooltip title={cell.getValue()} placement="left">
                    <Avatar alt="User Avatar" src={frdarklogo} sx={{ width: 24, height: 24 }} />
                </Tooltip>
            ),
            enableSorting: false,
            size: 50,
        },
        {
            accessorKey: 'note',
            header: 'Note',
            size: 200,
            Cell: ({ cell }) => (
                <Box sx={{
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '300px',
                    padding: '8px',
                }}>
                    {cell.getValue()}
                </Box>
            ),
        },
        {
            accessorKey: 'logDate',
            header: 'Date/Time',
            size: 150,
            Cell: ({ cell }) => offsetDate(new Date(cell.getValue())).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }),
        },
    ];

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '590px',
                maxHeight: '500px', 
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'hidden',
                borderLeft: '4px solid #ccc',
                borderRight: '4px solid #ccc',
                marginLeft: 0,
                backgroundColor: theme.palette.mode === 'light' ? 'inherit' : '#1D1D1D'
                
            }}
        >
            <Box 
                display="flex" 
                alignItems="center" 
                justifyContent="space-between" 
                width="100%"
                padding="8px 16px"
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowingAll(!showingAll)}
                    sx={{ marginLeft: showingAll ? '-6px' : '6px' }}
                >
                    {showingAll ? "Hide Complete" : "Show All"}
                </Button>
                <Typography 
                    variant='h6' 
                    gutterBottom
                    ml={showingAll ? 3 : 7}
                    style={{
                        fontWeight: 'bold', 
                        fontSize: '27px',
                    }}
                >
                    Notes
                </Typography>
                {id && (
                    <AddServiceLogNote onNoteAdded={fetchData} productConfigId={id} />
                )}
            </Box>

            <Box
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    height: 'calc(100% - 60px)', 
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <MaterialReactTable
                    columns={columns}
                    data={loading ? Array(4).fill({}) : (showingAll ? serviceLogData : filteredNotes)}
                    initialState={{
                        sorting: [{ id: 'logDate', desc: true }],
                        density: 'compact',
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                        onDoubleClick: () => handleRowDoubleClick(row.original),
                        sx: { cursor: 'pointer' },
                    })}
                    muiTableBodyCellProps={{
                        sx: {
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            backgroundColor: 'inherit',
                            fontWeight: 'bold',
                            '&:hover': { backgroundColor: 'inherit' },
                        },
                    }}
                    enableStickyHeader
                    state={{
                        isLoading: loading,
                    }}
                    muiTablePaperProps={{
                        sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                        },
                    }}
                    muiTableContainerProps={{
                        sx: {
                            overflowY: 'auto',
                            flex: 1,
                        },
                    }}
                />
            </Box>

            <Modal
                open={isEditModalOpen}
                onClose={handleCloseEditModal}
                closeAfterTransition
            >
                <Fade in={isEditModalOpen}>
                    <div>
                        <EditServiceLogNote note={noteToEdit} onUpdateNote={handleCloseEditModal} openSnackbar={openSnackbar} />
                    </div>
                </Fade>
            </Modal>

            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Service log note saved!
                </MuiAlert>
            </Snackbar>
        </Box>
    );
}

