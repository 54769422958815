import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Switch, TextField, Typography, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import dayjs from "dayjs";
import MuiAlert from '@mui/material/Alert';

import TimeInput from "./TimeInput";

export default function EditTimeSheetLog({ timeLog, onUpdateTimeSheetLog, updateSheet}) {

  const [clockInTime, setClockInTime] = useState(null);
  const [clockOutTime, setClockOutTime] = useState(null);
  const [description, setDescription] = useState("");
  const [hours, setHours] = useState(0);
  const [lunchOverride, setLunchOverride] = useState(false);
  const [lunchOverrideReason, setLunchOverrideReason] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("Please enter a valid time.");
  const [dialogText, setDialogText] = useState("ex. 7:00 am, 7:00 AM, 7:00am, 7:00AM, 0700");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (timeLog) {
        setClockInTime(formatClockTimes(timeLog.clockIn));
        setClockOutTime(formatClockTimes(timeLog.clockOut));
        setDescription(timeLog.description);
        setHours(timeLog.hours);
    }
    // eslint-disable-next-line
  }, [timeLog]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const formatClockTimes = (time) => {
    if (time === "-") {
      return null;
    }
    const i = timeLog.date.split("/");
    const t = time.split(" ")[1];
    const hs = time.split(" ")[0].split(":");
    var hour = Number(hs[0])
    if (t === "PM" && hour != 12) {
      hour += 12;
    }
    else if (t === "AM" && hour === 12) {
      hour = 0;
    }
    return dayjs((i[2]+"-"+i[0]+"-"+i[1]+" "+hour.toString()+":"+hs[1]));
  }

  const handleLunchOverride = (event, lunchOverride) => {
    setLunchOverride(lunchOverride);
  }

  const handleCalcHours = () => {
    let timeAdjustment = 0;
    let lunchAdjustment = 0;
    if (clockInTime && clockOutTime) {
        if (((clockOutTime - clockInTime) / 3600000) < 0) {
            timeAdjustment = 24;
            if (((clockOutTime - clockInTime) / 3600000 + timeAdjustment) >= 6 && !lunchOverride) {
              lunchAdjustment -= 0.5;
            }
        }
        else if (((clockOutTime - clockInTime) / 3600000) >= 6 && !lunchOverride) {
          lunchAdjustment -= 0.5;
        }
        return ((((clockOutTime - clockInTime) / 3600000 + timeAdjustment + lunchAdjustment) % 24).toFixed(2));
    }
    return (0);
  }

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleSave = () => {
    setLoading(true);

    if (lunchOverride && !lunchOverrideReason) {
      setDialogTitle("Reason for Meal Time Override")
      setDialogText("Please input reason for meal time deduction override.")
      setOpenDialog(true);
      setLoading(false);
    }
    else if (!isNaN(clockInTime) && !isNaN(clockOutTime) && clockInTime && clockOutTime) {
      const updatedTimeLog = {
          timesheetId: timeLog.id,
          clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
          clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
          hoursWorked: timeLog.hourType === "Regular Work" ? handleCalcHours() : hours,
          tsDescription: description ? description : "-",
          lunchOverride: lunchOverride,
          lunchOverrideReason: lunchOverrideReason,
      };

      axios
          .post('api/Timesheet/editTimeLog', updatedTimeLog)
          .then((response) => {
            onUpdateTimeSheetLog();
              setSnackbarOpen(true);
          })
          .catch((error) => {
              console.log(error);
              if (error.response && error.response.data) {
                  alert(error.response.data);
              } else {
                  alert("Error updating Time Log. Please try again.");
              }
          })
          .finally(() => {
              setLoading(false);
              updateSheet();
              onUpdateTimeSheetLog();
          });
    } else {
      setDialogTitle("Please enter a valid time.");
      setDialogText("ex. 7:00 am, 7:00 AM, 7:00am, 7:00AM, 0700");
      setOpenDialog(true);
      setLoading(false);
    }
  }; 

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}
    >
      <Button
        variant="outlined"
        onClick={onUpdateTimeSheetLog}
        style={{ position: 'absolute', top: '10px', right: '10px' }}
      >
        X
      </Button>

      <Typography id="modal-modal-title" variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
        Edit Time Entry
      </Typography>

      {timeLog.hourType === "Regular Work" ? (
        <>
          <Stack width={"100%"} marginBottom={"10px"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeInput
                label="Clock In Time"
                value={clockInTime}
                onChange={setClockInTime}
              />
            </LocalizationProvider> 
          </Stack>

          <Stack width={"100%"} marginBottom={"10px"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeInput
                label="Clock Out Time"
                value={clockOutTime}
                onChange={setClockOutTime}
                disabled={!clockInTime}
              />
            </LocalizationProvider>
          </Stack>

          <Stack width={"100"} marginBottom={"10px"} flexDirection={"row"}>
            <Typography marginTop={"10px"}>Override Meal Break</Typography>
            <Switch
              checked={lunchOverride}
              exclusive = {true}
              onChange={handleLunchOverride}
              aria-label="LunchTaken"
            />
          </Stack>

          {lunchOverride ? (
            <TextField
              label="Reason for Override"
              required
              value={lunchOverrideReason}
              onChange={(e) => setLunchOverrideReason(e.target.value)}
              style={{ width: "100%", marginBottom: "20px" }}
            />
          ) : (
            null
          )}
          
        </>
      ) : (
        <Stack width={"100%"} marginBottom={"10px"}>
          <TextField
            value={hours}
            label="Input Hours"
            placeholder={0}
            onChange={(e) => setHours((e.target.value)*1)}
            InputProps={{
              type: "number",
              sx: {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                      display: 'none'
                    },
                    '& input[type=number]': {
                      MozAppearance: 'textfield'
                    },
                  }
            }}
            inputProps={{maxLength: 5}}
          />
        </Stack>
      )}

      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ width: "100%", marginBottom: "20px" }}
      />

      <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>

        <Button
          style={{ color: "green", marginTop: "10px" }}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              {dialogText}
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                  Close
              </Button>
          </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Time log saved!
          </MuiAlert>
      </Snackbar>
    </Box>
  );
}