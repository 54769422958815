import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, Tab, Tabs, Typography, TextField } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import AddDevice from './AddDevice';
import EditRow from './EditRow';
import AddNozzleMaster from './AddNozzleMaster';
import DeleteDevice from './DeleteDevice';
import DeleteNozzleTab from './DeleteNozzleTab';
import StarterTemplates from './Templates/StarterTemplates.json';

export default function MidDeviceSection({ id, prodConfigId, prodType }) {
  const [allDevices, setAllDevices] = useState([]);
  const [productConfigId, setProductConfigId] = useState(prodConfigId);
  const [productType, setProductType] = useState(prodType);
  const [nozzleProgrammingDataList, setNozzleProgrammingDataList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [editRowData, setEditRowData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [nozzleIds, setNozzleIds] = useState([]);
  const [dpId, setDpId] = useState(null);
  const [npId, setNpId] = useState(null);
  const [correctTemplate, setCorrectTemplate] = useState();
  const [filteredTemplateData1, setFilteredTemplateData1] = useState([]);
  const [filteredTemplateData2, setFilteredTemplateData2] = useState([]);
  const [filteredTemplateData3, setFilteredTemplateData3] = useState([]);
  const [requestData1, setRequestData1] = useState([]);
  const [requestData2, setRequestData2] = useState([]);
  const [requestData3, setRequestData3] = useState([]);
  const [editingTabIndex, setEditingTabIndex] = useState(null);
  const [currentTabNames, setCurrentTabNames] = useState();
  const [newTabName, setNewTabName] = useState('');
  const [productInfo, setProductInfo] = useState({});
  const [unitNumber, setUnitNumber] = useState(1);
  const [riserCount, setRiserCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [productConfigInfo, setProductConfigInfo] = useState({});
  const [pcInfo, setPcInfo] = useState({});
  const [existingTabNames, setExistingTabNames] = useState();
  const [existingDevices, setExistingDevices] = useState();
  const [existingNozzleUnits, setExistingNozzleUnits] = useState(0);
  const [templateTabsComplete, setTemplateTabsComplete] = useState(false);
  const [templateDevicesComplete, setTemplateDevicesComplete] = useState(false);
  const [templateNozzlesComplete, setTemplateNozzlesComplete] = useState(false);
  const [templateSaveComplete, setTemplateSaveComplete] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    setProductType(prodType);
    setProductConfigId(prodConfigId);
  }, [id, prodConfigId, prodType]);

  useEffect(() => {
    setCurrentTabNames(nozzleProgrammingDataList.map(item => item.name));
  }, [nozzleProgrammingDataList]);

  const handleModalOpen = () => setEditModalOpen(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => { 
    setEditModalOpen(false);
    setSelectedRow(null); 
  };
  
  const resetOnClose = () => {
    setSelectedRow(null);
  }

  useEffect(() => {
    fetchDeviceInfo();
    fetchPcInfo();
    fetchProductData();
  }, [prodConfigId]);

  useEffect(() => {
    checkTypeAndFetchNozzleInfo();
  }, [prodType, prodConfigId]);

  const fetchInfo = async () => {
    if (prodType === 'ED' || prodType === 'OW') {
      return;
    }
    
    if (prodConfigId) {
      try {
        const productInfoEndpoint = `api/ProductSummary/getProductSummaryFromPcId/${prodConfigId}`;
        const response = await axios.get(productInfoEndpoint);
        setRiserCount(response.data.riserCount);
        setPcInfo(response.data);
        setIsLoading(false);
      }
      catch (error) {
        setIsLoading(false);
        console.error("Error retrieving data", error); 
      }
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [prodConfigId]);

  useEffect(() => {
    if (prodType === 'ED') {
      return;
    }

    if (prodType === 'OW') {
      setCorrectTemplate(JSON.stringify(StarterTemplates.starterTemplates[prodType][unitNumber - 1].tabs));

      const dataToFilter = StarterTemplates.starterTemplates[prodType][unitNumber - 1].tabs

      setRequestData2(dataToFilter[0].devices.map((device) => ({
        dpId: 0,
        productConfigurationId: prodConfigId,
        ...device
      })));
    }

    setCorrectTemplate(JSON.stringify(StarterTemplates.starterTemplates[prodType][unitNumber - 1].tabs.filter(tab => tab.tabIndex >= 0 && tab.tabIndex <= riserCount)));

    const dataToFilter = StarterTemplates.starterTemplates[prodType][unitNumber - 1].tabs.filter(tab => tab.tabIndex >= 0 && tab.tabIndex <= riserCount);

    setFilteredTemplateData1(dataToFilter.map(entry => ({ [entry.tabIndex]: entry.tabName})));
    setFilteredTemplateData2(dataToFilter[0]?.devices ? [dataToFilter[0]?.devices] : []);
    setFilteredTemplateData3(dataToFilter.slice(1).map(entry => (Object.values(entry.devices))));
  }, [riserCount, prodConfigId]);

  useEffect(() => {
    const requestData = filteredTemplateData1.map((item, index) => {
      const nozzleArrangementName = Object.values(item)[0];
  
      return {
        nozzleId: 0,
        productConfigurationId: prodConfigId,
        nozzleArrangementName: nozzleArrangementName
      };
    });

    setRequestData1(requestData);
  }, [filteredTemplateData1]);

  useEffect(() => {
    if (prodType === 'ED' || prodType === 'OW') {
      return;
    }

    else {
      let newDevices = [];
      filteredTemplateData2.map((item, index) => {
        let device = Object.values(item);
        device.forEach((config) => {
    
          newDevices.push({
            dpId: 0,
            productConfigurationId: prodConfigId,
            ...config
          });
      })});

      setRequestData2(newDevices);
    }
  }, [filteredTemplateData2]);

  const prepTemplateData3 = () => {
    if (prodType === 'ED' || prodType === 'OW') {
      return;
    }

    let newNozzleConfigs = [];
    if (filteredTemplateData3 && nozzleIds) {
      filteredTemplateData3.map((item, index) => {
        let nozzleId = nozzleIds[index];
        let unit = Object.values(item);
        unit.forEach((config) => {
    
          newNozzleConfigs.push({
            npId: 0,
            nozzleId: nozzleId,
            productConfigurationId: prodConfigId,
            ...config
          });
        })
    })};

    setRequestData3(newNozzleConfigs);
  };

  const saveTemplateTabs = async () => {
    if (requestData1 && prodConfigId && (existingTabNames === 0 && riserCount > 0)) {
      try {
        const endpoint = 'api/NozzleMaster/saveTemplateNozzleMasters';
        const response = await axios.post(endpoint, requestData1);
        setTemplateTabsComplete(true);
        saveTemplateRiserConfigurations();

      }
      catch (error) {
        console.error(`Error saving tab names: ${error}`);
      }
    }
    else {
      return;
    }
  };

  const saveTemplateGenericDevices = async () => {
    if (requestData2 && prodConfigId && existingDevices === 0) {
      try {
        const endpoint = 'api/DeviceInfo/saveGeneralDevicesFromTemplate';
        const response = await axios.post(endpoint, requestData2);
        fetchDeviceInfo();
        setTemplateDevicesComplete(true);
      }
      catch (error) {
        console.error(`Error saving General Device unit configurations: ${error}`);
      }
    }
    else {
      return;
    }
  };

  const saveTemplateRiserConfigurations = async () => {
    if (prodType === 'ED' || prodType === 'OW') {
      return;
    };

    if (!templateNozzlesComplete && existingNozzleUnits <= 0 && existingTabNames > 0 && riserCount > 0) {
      const response = await axios.get(`api/NozzleMaster/nozzleMasterFromProductConfigId/${prodConfigId}`);
      const nozzleData = response.data;
      const ids = nozzleData.map(item => item.nozzleId);
      const tabNames = nozzleData.map(item => item.nozzleArrangementName);
      setExistingTabNames(tabNames.length);
      setNozzleIds(ids);

      if (existingNozzleUnits === 0 && !templateNozzlesComplete && (existingTabNames > 0 && riserCount > 0)) {
        await checkTypeAndFetchNozzleInfo();
        await prepTemplateData3();
        try {
          const endpoint = 'api/NozzleProgramming/saveTemplateTabRiserDevices';
          const data = (prodType === 'CF' ? requestData3.flat() : requestData3);
          const response = await axios.post(endpoint, data);

          await setTemplateNozzlesComplete(true);
          await checkTypeAndFetchNozzleInfo();
        }
        catch (error) {
          console.error(`Error saving riser unit configurations: ${error}`);
        }
      }
      else {
        return;
      }
    }
  };

  useEffect(() => {
    fetchDeviceInfo();
    checkTypeAndFetchNozzleInfo();
  }, [templateSaveComplete, templateTabsComplete, ]);

  useEffect(() => {
    saveTemplateTabs();
  }, [requestData1]);

  useEffect(() => {
    saveTemplateGenericDevices();
    fetchDeviceInfo();
  }, [requestData2]);

  useEffect(() => {
    saveTemplateRiserConfigurations();
  }, [nozzleIds]);

  const fetchDeviceInfo = async () => {
    setSelectedRow(null);
    setTabIndex(0);
    if (prodConfigId) {
      try {
        const response = await axios.get(`api/DeviceInfo/getDeviceInfoFromPcId/${prodConfigId}`);
        setExistingDevices((Array.isArray(response.data) ? response.data : [response.data]).length);
        setAllDevices(Array.isArray(response.data) ? response.data : [response.data]);
      } catch (error) {
        console.error("Error retrieving all devices: ", error);
      }
    }
  };

  const fetchPcInfo = async () => {
    if (prodConfigId) {
      try {
        const pcEndpoint = `api/ProductConfiguration/productConfig/${prodConfigId}`;
        const response = await axios.get(pcEndpoint);
        setProductConfigInfo(response.data);
      }
      catch (error) {
        console.error(`Error fetching the config info: ${error}`);
      }
    }
  };

  const fetchProductData = async () => {
    if (prodType === 'ED' || prodType === 'OW') {
      return;
    }

    try {
      const productInfoEndpoint = `api/ProductSummary/getProductSummaryFromPcId/${prodConfigId}`;
      const response = await axios.get(productInfoEndpoint);
      setProductInfo(response.data);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("Error retrieving data", error); 
    }
  };

  const checkTypeAndFetchNozzleInfo = async () => {
    setSelectedRow(null);
    if (prodType && prodConfigId) {
      try {
        if (prodType === 'BSU' || prodType === 'CF') {
          const response = await axios.get(`api/NozzleMaster/nozzleMasterFromProductConfigId/${prodConfigId}`);
          const nozzleData = response.data;
          const ids = nozzleData.map(item => item.nozzleId);
          const tabNames = nozzleData.map(item => item.nozzleArrangementName);
          setExistingTabNames(tabNames.length);
          
          setNozzleIds(ids);
          const nozzleProgrammingData = await Promise.all(nozzleData.map(async (nozzle) => {
            const programmingResponse = await axios.get(`api/NozzleProgramming/getAllNpFromNozzleId/${nozzle.nozzleId}`);
            setExistingNozzleUnits(existingNozzleUnits + (Array.isArray(programmingResponse.data) ? programmingResponse.data : [programmingResponse.data]).length);
            return {
              name: nozzle.nozzleArrangementName,
              nozzleProgrammings: programmingResponse.data
            };
          }));
          
          setNozzleProgrammingDataList(nozzleProgrammingData);
        }
      } catch (error) {
        console.error("Error handling the nozzle info: ", error);
      }
    }
  };

  const saveCursorPosition = () => {
    if (inputRef.current) {
      const { selectionStart, selectionEnd } = inputRef.current;
      return { selectionStart, selectionEnd };
    }
    return { selectionStart: null, selectionEnd: null };
  };
  
  const restoreCursorPosition = (start, end) => {
    if (inputRef.current && start !== null && end !== null) {
      inputRef.current.setSelectionRange(start, end);
    }
  };

  useEffect(() => {
    if (productInfo) {
      setRiserCount(productInfo.riserCount);
    }
    else {
      return;
    };
  }, [productInfo]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setSelectedRow(null);
    setEditRowData(null);
  };

  const handleAddTab = () => {
    const newTabName = `Nozzle ${nozzleProgrammingDataList.length + 1}`;
    setNozzleProgrammingDataList([...nozzleProgrammingDataList, { name: newTabName, nozzleProgrammings: [] }]);
    setTabIndex(nozzleProgrammingDataList.length + 1); 
  };

  const handleUpdateTabIndex = () => {
    const newIndex = Math.max(0, nozzleProgrammingDataList.length - 1);
    setTabIndex(newIndex);
  };

  const handleUpdateTabName = async (tabIndex, newTabName) => {
    const formatTabName = (name) => {
        const minorWords = ['and', 'or', 'the', 'of', 'in', 'on', 'for', 'with'];

        return name
            .toLowerCase()
            .split(' ')
            .map((word, index) => {
                if (index === 0 || !minorWords.includes(word)) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                }
                return word;
            })
            .join(' '); 
    };

    try {
        if (tabIndex > 0 && nozzleIds.length > 0) {
            const nozzleId = nozzleIds[tabIndex - 1];

            const formattedTabName = formatTabName(newTabName);

            const response = await axios.put(
                `api/NozzleMaster/updateNozzleMasterName/${nozzleId}/`,
                formattedTabName,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            checkTypeAndFetchNozzleInfo();
        }
    } 
    catch (error) {
        console.error('Error updating the tab name: ', error);
    }
};

  const startEditingTabName = (index, currentName) => {
    setEditingTabIndex(index);
    setNewTabName(currentName);
  };

  const saveTabName = async () => {
    const uppercaseCurrentTabNames = currentTabNames.map((name) => name.toUpperCase());
    
    if (uppercaseCurrentTabNames.includes(newTabName.toUpperCase())) {
      setEditingTabIndex(null); 
    } else {
      const updatedNozzleList = [...nozzleProgrammingDataList];
      updatedNozzleList[editingTabIndex - 1].name = newTabName.toUpperCase();
      setNozzleProgrammingDataList(updatedNozzleList);

      await handleUpdateTabName(editingTabIndex, newTabName);
      setEditingTabIndex(null); 
    }
  };

  const handleTabNameChange = (event) => {
    const { selectionStart, selectionEnd } = saveCursorPosition();
    setNewTabName(event.target.value);
    setTimeout(() => restoreCursorPosition(selectionStart, selectionEnd), 0);
  };

  const handleTabNameKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === 'return') {
      saveTabName();
    }
  };

  const renderTabLabel = (name, index) => {
    const uppercaseName = name.toUpperCase();
  
    if (editingTabIndex === index) {
      return (
        <TextField
          value={newTabName.toUpperCase()}
          onChange={handleTabNameChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === 'return') {
              saveTabName();
            }
            else if (event.key === 'Escape') {
              setNewTabName(name);
              setEditingTabIndex(null);
            }
          }}
          onBlur={() => {
            setNewTabName(name);
            setEditingTabIndex(null);
          }}
          autoFocus
          inputRef={inputRef}
          inputProps={{ 
            style: { textTransform: 'uppercase' } 
          }}
          sx={{
            backgroundColor: 'transparent',
            width: 'auto',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            maxWidth: '100%',
          }}
        />
      );
    }
    return (
      <Typography 
        onDoubleClick={() => startEditingTabName(index, uppercaseName)}
        style={{ textTransform: 'uppercase' }}
      >
        {uppercaseName}
      </Typography>
    );
  };

  const commonColumns = [
    { accessorKey: 'deviceName', header: 'Name' },
    { accessorKey: 'deviceType', header: 'Type' },
    { accessorKey: 'macAddress', header: 'MAC Address' },
    { accessorKey: 'sysMsgSerialNumber', header: 'S Number' },
    { accessorKey: 'internalIpAddress', header: 'Internal IP Address' },
    { accessorKey: 'internalHttp', header: 'Internal HTTP' },
    { accessorKey: 'internalMod', header: 'Internal MOD' },
    { accessorKey: 'internalRtsp', header: 'Port RTSP' },
    { accessorKey: 'externalHttp', header: 'External HTTP' },
    { accessorKey: 'externalRtsp', header: 'External RTSP' },
    { accessorKey: 'externalMod', header: 'External MOD'},
  ];

  const additionalColumns = [
    { accessorKey: 'deviceSerialNumber', header: 'Serial #' },
    { accessorKey: 'devicePassword', header: 'Default Password' },
  ]

  const getNozzleDataForTab = () => {
    if (tabIndex === 0) {
      return allDevices;
    }

    const selectedNozzle = nozzleProgrammingDataList[tabIndex - 1];
    
    if (selectedNozzle) {
      if (selectedNozzle.nozzleProgrammings.length > 0) {
        return selectedNozzle.nozzleProgrammings;
      }
      else {
        if (selectedNozzle) {
          return selectedNozzle.nozzleProgrammings;
        }
      }
    }
    return;
  };

  const handleRowClick = (row) => {
    if (selectedRow === row.original) {
      setDpId(null);
      setNpId(null);
      setSelectedRow(null);
      setEditRowData(null);
    } else {
      if (JSON.stringify(row.original.dpId)) {
        setDpId(JSON.stringify(row.original.dpId));
      }
      else if (JSON.stringify(row.original.npId)) {
        setNpId(JSON.stringify(row.original.npId));
      }
      setSelectedRow(row.original);
      setEditRowData(row.original);
    }
  };

  const handleRowDoubleClick = (row) => {
    setSelectedRow(row.original);
    setEditRowData(row.original);
    handleModalOpen();
  };

  const handleEditButtonClick = () => {
    setEditModalOpen(true);
  };

  const renderTemplate = () => {
    const dynamicColumns = tabIndex > 0
      ? [...commonColumns, ...additionalColumns]
      : commonColumns;

    switch (productType) {
      case 'BSU':
      case 'CF':
        return (
          <>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label={`${productType} Tabs`} variant='scrollable' scrollButtons='auto'>
              <Tab label="General Devices" disableRipple disableFocusRipple />
              {nozzleProgrammingDataList.map((item, index) => (
                <Tab key={index} label={renderTabLabel(item.name, index+1)} disableRipple disableFocusRipple />
              ))}
              <AddNozzleMaster pcId={prodConfigId} onAddNozzle={handleAddTab} onSave={checkTypeAndFetchNozzleInfo} />
            </Tabs>
            <MaterialReactTable
              columns={dynamicColumns}
              data={getNozzleDataForTab() ?? []}
              enableStickyHeader={true}
              initialState={{pagination: {pageSize: 20}}}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => handleRowClick(row),
                onDoubleClick: () => handleRowDoubleClick(row),
                sx: {
                  cursor: 'pointer',
                  backgroundColor: selectedRow === row.original ? 'rgba(0, 0, 255, 0.1)' : 'inherit',
                },
              })}
              muiTableContainerProps={{
                sx: { maxHeight: '40vh', overflow: 'auto' },
              }}
            />
          </>
        );
      case 'OW':
      case 'ED':
        return (
          <>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label={`${productType} Tabs`} variant='scrollable' scrollButtons='auto'>
              <Tab label="General Devices" />
            </Tabs>
            <MaterialReactTable
              columns={commonColumns}
              data={getNozzleDataForTab() ?? []}
              enableStickyHeader={true}
              initialState={{pagination: {pageSize: 20}}}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => handleRowClick(row),
                onDoubleClick: () => handleRowDoubleClick(row),
                sx: {
                  cursor: 'pointer',
                  backgroundColor: selectedRow === row.original ? 'rgba(0, 0, 255, 0.1)' : 'inherit',
                },
              })}
              muiTableContainerProps={{
                sx: { maxHeight: '40vh', overflow: 'auto' },
              }}
            />
          </>
        );
      default:
        return (
          <>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label={`${productType} Tabs`} variant='scrollable' scrollButtons='auto'>
              <Tab label="General Devices" />
              {nozzleProgrammingDataList.map((item, index) => (
                <Tab key={index} label={item.name} />
              ))}
              <AddNozzleMaster pcId={prodConfigId} onAddNozzle={handleAddTab} onSave={checkTypeAndFetchNozzleInfo} />
            </Tabs>
            <MaterialReactTable
              columns={commonColumns}
              data={getNozzleDataForTab() ?? []}
              enableStickyHeader={true}
              initialState={{pagination: {pageSize: 20}}}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => handleRowClick(row),
                onDoubleClick: () => handleRowDoubleClick(row),
                sx: {
                  cursor: 'pointer',
                  backgroundColor: selectedRow === row.original ? 'rgba(0, 0, 255, 0.1)' : 'inherit',
                },
              })}
              muiTableContainerProps={{
                sx: { maxHeight: '40vh', overflow: 'auto' },
              }}
            />
          </>
        );
    }
  };

  return (
    <Grid
      container
      minWidth={'100%'}
      sx={{ marginBottom: '1%', marginTop: '1%', padding: '5% 0', width: '100%', height: '100%', overflow: 'auto' }}
    >
      <Grid container spacing={2} justifyContent="flex-start" alignItems='center' >
        {selectedRow !== null && (
          <Grid item sx={{marginBottom: '-2.5%'}}>
            <DeleteDevice
              devId={dpId}
              nozId={npId}
              onDeviceDeleted={fetchDeviceInfo}
              onNozzleDeleted={checkTypeAndFetchNozzleInfo}
              reset={resetOnClose}
              selectedRow={selectedRow}
            />
          </Grid>
        )}
        {tabIndex > 0 && (
          <Grid item sx={{marginBottom: '-2.5%'}}>
            <DeleteNozzleTab 
              nozzleIds={nozzleIds} 
              tabIndex={tabIndex} 
              onTabDeleted={checkTypeAndFetchNozzleInfo} 
              onUpdateTabIndex={handleUpdateTabIndex} 
              nozzleTabName={nozzleProgrammingDataList[tabIndex - 1]?.name} 
              modalClose={() => setSelectedRow(null)}
            />
          </Grid>
        )}
      </Grid>
  
      <Grid container spacing={2} justifyContent="flex-end" alignItems='center'>
        {selectedRow !== null && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalOpen}
            >
              Edit Selected Device
            </Button>
          </Grid>
        )}
        <Grid item>
          <AddDevice
            productConfigId={prodConfigId}
            nozIds={nozzleIds}
            tabInd={tabIndex}
            allDevice={allDevices}
            onDeviceAdded={fetchDeviceInfo}
            onNozzleAdded={checkTypeAndFetchNozzleInfo}
            reset={resetOnClose}
          />
        </Grid>
      </Grid>
  
      {editRowData && (
        <EditRow
          open={editModalOpen}
          onClose={handleEditModalClose}
          rowInfo={editRowData}
          onDeviceRowUpdated={fetchDeviceInfo}
          onNozzleRowUpdated={checkTypeAndFetchNozzleInfo}
          tabInd={tabIndex}
        />
      )}
  
      <Grid item xs={12} flexGrow={1}>
        {renderTemplate()}
      </Grid>
    </Grid>
  );
}
