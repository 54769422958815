import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, TextField, Typography, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function AddDevice({productConfigId, nozIds, tabInd, nozzleProgList, allDevice, onDeviceAdded, onNozzleAdded, reset}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [allDevices, setAllDevices] = useState(allDevice);
    const [nozzleIds, setNozzleIds] = useState([]);
    const [newDeviceRowData, setNewDeviceRowData] = useState({productConfigurationId: productConfigId});
    const [newNozzleRowData, setNewNozzleRowData] = useState({nozzleId: nozIds[0]});
    const [tabIndex, setTabIndex] = useState(tabInd);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleSnackbarClose = () => setSnackbarOpen(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        setModalOpen(false);
        reset();
    };

    useEffect(() => {
        setTabIndex(tabInd);
        setNewDeviceRowData({productConfigurationId: productConfigId});
        setNozzleIds(nozIds);
    }, [tabInd, nozIds, productConfigId]);

    useEffect(() => {
        setNewNozzleRowData({nozzleId: nozzleIds[tabIndex - 1]});
    }, [tabIndex, nozzleIds]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (tabIndex === 0) {
            setNewDeviceRowData((prevData) => ({ ...prevData, [name]: value })); 
        } else {
            setNewNozzleRowData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const handleFormSubmit = async () => {
        try {
            let response;
            if (tabIndex === 0) {
                const endpoint = 'api/DeviceInfo/createDevice';
                response = await axios.post(endpoint, newDeviceRowData);
            } else {
                const endpoint = 'api/NozzleProgramming/createNozzle';
                response = await axios.post(endpoint, newNozzleRowData);
            }
    
            if (response.status === 400) {
                setSnackbarMessage(response.data); 
                setSnackbarOpen(true);
                return;
            }
    
            
            if (tabIndex === 0) {
                setAllDevices((prevData) => [...prevData, newDeviceRowData]);
                onDeviceAdded();
            } else {
                onNozzleAdded();
            }
    
            setSnackbarMessage('New Device Added!');
            setSnackbarOpen(true);
            handleModalClose();
    
            if (tabIndex === 0) {
                setNewDeviceRowData({ productConfigurationId: productConfigId });
            } else {
                setNewNozzleRowData({ nozzleId: nozzleIds[tabIndex - 1] });
            }
        } catch (error) {
            const errorMessage = error.response && error.response.data ? error.response.data : "An error occurred. Please try again.";
            alert(`${errorMessage} \nPlease complete the form and then submit again.`);
            console.error("Error adding new entry: ", error);
        }
    };
    

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleModalOpen}>
                Add New Device
            </Button>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Button variant="outlined" onClick={handleModalClose} style={{ position: 'absolute', top: '10px', right: '10px' }}>X</Button>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add New Device
                    </Typography>
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        <Grid item xs={6}>
                            <TextField label="Name" name="deviceName" fullWidth value={tabIndex === 0 ? newDeviceRowData.deviceName : newNozzleRowData.deviceName} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Type" name="deviceType" fullWidth value={tabIndex === 0 ? newDeviceRowData.deviceType : newNozzleRowData.deviceType} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="MAC Address" name="macAddress" fullWidth value={tabIndex === 0 ? newDeviceRowData.macAddress : newNozzleRowData.macAddress} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="S-Number" name="sysMsgSerialNumber" fullWidth value={tabIndex === 0 ? newDeviceRowData.sysMsgSerialNumber : newNozzleRowData.sysMsgSerialNumber} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Internal IP Address" name="internalIpAddress" fullWidth value={tabIndex === 0 ? newDeviceRowData.internalIpAddress : newNozzleRowData.internalIpAddress} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Internal HTTP" name="internalHttp" fullWidth value={tabIndex === 0 ? newDeviceRowData.internalHttp : newNozzleRowData.internalHttp} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Internal MOD" name="internalMod" fullWidth value={tabIndex === 0 ? newDeviceRowData.internalMod : newNozzleRowData.internalMod} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Port RTSP" name="internalRtsp" fullWidth value={tabIndex === 0 ? newDeviceRowData.internalRtsp : newNozzleRowData.internalRtsp} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="External HTTP" name="externalHttp" fullWidth value={tabIndex === 0 ? newDeviceRowData.externalHttp : newNozzleRowData.externalHttp} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="External RTSP" name="externalRtsp" fullWidth value={tabIndex === 0 ? newDeviceRowData.externalRtsp : newNozzleRowData.externalRtsp} onChange={handleInputChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="External MOD" name="externalMod" fullWidth value={tabIndex === 0 ? newDeviceRowData.externalMod : newNozzleRowData.externalMod} onChange={handleInputChange} />
                        </Grid>
                        {tabIndex > 0 && (
                            <Grid item xs={6}>
                                <TextField label="Serial Number" name="deviceSerialNumber" fullWidth value={newNozzleRowData.deviceSerialNumber || ''} onChange={handleInputChange} />
                            </Grid>
                        )}
                        {tabIndex > 0 && (
                            <Grid item xs={6}>
                                <TextField label="Device Password" name="devicePassword" fullWidth value={newNozzleRowData.devicePassword || ''} onChange={handleInputChange} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

