import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Typography, Select, Snackbar, CircularProgress, TextField, Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function EditNetworkInfo({ lanDhcp, wanDhcp, netInfo, onSave }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const [lanIpAddress, setLanIpAddress] = useState("");
  const [lanSubnetMask, setLanSubnetMask] = useState("");
  const [lanGateway, setLanGateway] = useState("");
  const [lanDns1, setLanDns1] = useState("");
  const [lanDns2, setLanDns2] = useState("");
  const [lanDhcpValue, setlanDhcpValue] = useState(lanDhcp);
  const [wanIpAddress, setWanIpAddress] = useState("");
  const [wanSubnetMask, setWanSubnetMask] = useState("");
  const [wanGateway, setWanGateway] = useState("");
  const [wanDns1, setWanDns1] = useState("");
  const [wanDns2, setWanDns2] = useState("");
  const [wanDhcpValue, setwanDhcpValue] = useState(wanDhcp);
  const [cellIpAddress, setCellIpAddress] = useState("");
  const [cellSubnetMask, setCellSubnetMask] = useState("");
  const [cellGateway, setCellGateway] = useState("");
  const [cellDns1, setCellDns1] = useState("");
  const [cellDns2, setCellDns2] = useState("");
  const [sim1Carrier, setSim1Carrier] = useState("");
  const [sim1Imei, setSim1Imei] = useState("");
  const [sim1Phone, setSim1Phone] = useState("");
  const [sim2Carrier, setSim2Carrier] = useState("");
  const [sim2Imei, setSim2Imei] = useState("");
  const [sim2Phone, setSim2Phone] = useState("");
  const [router, setRouter] = useState("");
  const [ddns, setDdns] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [defaultPassword, setDefaultPassword] = useState("");
  const [networkInfo, setNetworkInfo] = useState();

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
  
    const formattedNumber = phoneNumber.replace(/\D+/g, ""); // Remove non-numeric characters
    const areaCode = formattedNumber.slice(0, 3);
    const firstThree = formattedNumber.slice(3, 6);
    const lastFour = formattedNumber.slice(6);
  
    return `(${areaCode}) ${firstThree}-${lastFour}`;
  };

  const saveNetworkInfo = () => {
    const newNetworkInfo = {
      networkId: netInfo.networkId,
      productConfigId: netInfo.productConfigId,
      lanIpAddress,
      lanSubnetMask,
      lanGateway,
      lanDns1,
      lanDns2,
      lanDhcp,
      wanIpAddress,
      wanSubnetMask,
      wanGateway,
      wanDns1,
      wanDns2,
      wanDhcp,
      cellIpAddress,
      cellSubnetMask,
      cellGateway,
      cellDns1,
      cellDns2,
      sim1Carrier,
      sim1Imei,
      sim1Phone,
      sim2Carrier,
      sim2Imei,
      sim2Phone,
      router,
      ddns,
      macAddress,
      serialNumber,
      defaultPassword,
    };

    setLoading(true);

    axios
      .put("api/NetworkInfo/updateNetworkInfo", newNetworkInfo)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        alert(JSON.stringify(error.response?.data) || "Error saving network info. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        onSave();
        handleClose();
      });
  };

  useEffect(() => {
    setNetworkInfo(netInfo);
  }, [netInfo]);

  useEffect(() => {
    if (networkInfo) {
      setLanIpAddress(networkInfo.lanIpAddress || "");
      setLanSubnetMask(networkInfo.lanSubnetMask || "");
      setLanGateway(networkInfo.lanGateway || "");
      setLanDns1(networkInfo.lanDns1 || "");
      setLanDns2(networkInfo.lanDns2 || "");
      setlanDhcpValue(lanDhcp);
      setWanIpAddress(networkInfo.wanIpAddress || "");
      setWanSubnetMask(networkInfo.wanSubnetMask || "");
      setWanGateway(networkInfo.wanGateway || "");
      setWanDns1(networkInfo.wanDns1 || "");
      setWanDns2(networkInfo.wanDns2 || "");
      setwanDhcpValue(wanDhcp);
      setCellIpAddress(networkInfo.cellIpAddress || "");
      setCellSubnetMask(networkInfo.cellSubnetMask || "");
      setCellGateway(networkInfo.cellGateway || "");
      setCellDns1(networkInfo.cellDns1 || "");
      setCellDns2(networkInfo.cellDns2 || "");
      setSim1Carrier(networkInfo.sim1Carrier || "");
      setSim1Imei(networkInfo.sim1Imei || "");
      setSim1Phone(networkInfo.sim1Phone || "");
      setSim2Carrier(networkInfo.sim2Carrier || "");
      setSim2Imei(networkInfo.sim2Imei || "");
      setSim2Phone(networkInfo.sim2Phone || "");
      setRouter(networkInfo.router || "");
      setDdns(networkInfo.ddns || "");
      setMacAddress(networkInfo.macAddress || "");
      setSerialNumber(networkInfo.serialNumber || "");
      setDefaultPassword(networkInfo.defaultPassword || "");
    }
  }, [networkInfo]);

  const gridItemProps = {
    xs: 6,
    container: true,
    justifyContent: "center",
  };

  return (
    <>
      <Button variant="contained" color="primary" style={{ display: 'table-header-group', marginLeft: '10%', marginTop: '15%', marginBottom: '15%' }} onClick={handleOpen}>Edit</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            maxHeight: '575px',
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ paddingBottom: "20px" }}
          >
            Edit Network Info
          </Typography>

          <Grid
            container
            rowSpacing={0}
            style={{ padding: "30px" }}
          >

            <Grid item {...gridItemProps}>
              <TextField
                label="LAN IP Address"
                value={lanIpAddress}
                onChange={(e) => setLanIpAddress(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="LAN Subnet Mask"
                value={lanSubnetMask}
                onChange={(e) => setLanSubnetMask(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="LAN Gateway"
                value={lanGateway}
                onChange={(e) => setLanGateway(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="LAN Dns 1"
                value={lanDns1}
                onChange={(e) => setLanDns1(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="LAN Dns 2"
                value={lanDns2}
                onChange={(e) => setLanDns2(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            
            <Grid item {...gridItemProps}>
              <TextField
                label="WAN IP Address"
                value={wanIpAddress}
                onChange={(e) => setWanIpAddress(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="WAN Subnet Mask"
                value={wanSubnetMask}
                onChange={(e) => setWanSubnetMask(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="WAN Gateway"
                value={wanGateway}
                onChange={(e) => setWanGateway(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="WAN Dns 1"
                value={wanDns1}
                onChange={(e) => setWanDns1(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="WAN Dns 2"
                value={wanDns2}
                onChange={(e) => setWanDns2(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="Cellular IP Address"
                value={cellIpAddress}
                onChange={(e) => setCellIpAddress(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="Cellular Subnet Mask"
                value={cellSubnetMask}
                onChange={(e) => setCellSubnetMask(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="Cellular Gateway"
                value={cellGateway}
                onChange={(e) => setCellGateway(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="Cellular Dns 1"
                value={cellDns1}
                onChange={(e) => setCellDns1(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="Cellular Dns 2"
                value={cellDns2}
                onChange={(e) => setCellDns2(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 1 Carrier"
                value={sim1Carrier}
                onChange={(e) => setSim1Carrier(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 1 IMEI"
                value={sim1Imei}
                onChange={(e) => setSim1Imei(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 1 Phone"
                value={formatPhoneNumber(sim1Phone)}
                onChange={(e) => setSim1Phone(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 2 Carrier"
                value={sim2Carrier}
                onChange={(e) => setSim2Carrier(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 2 IMEI"
                value={sim2Imei}
                onChange={(e) => setSim2Imei(e.target.value)}
                style={{ width: "100%", marginBottom: "20px"}}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="SIM 2 Phone"
                value={formatPhoneNumber(sim2Phone)}
                onChange={(e) => setSim2Phone(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="Router"
                value={router}
                onChange={(e) => setRouter(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="DDNS"
                value={ddns}
                onChange={(e) => setDdns(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="MAC Address"
                value={macAddress}
                onChange={(e) => setMacAddress(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="Serial Number"
                value={serialNumber}
                onChange={(e) => setSerialNumber(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
              />
            </Grid>
            <Grid item {...gridItemProps}>
              <TextField
                label="Default Password"
                value={defaultPassword}
                onChange={(e) => setDefaultPassword(e.target.value)}
                style={{ width: "100%", marginBottom: "20px" }}
              />
            </Grid>
          </Grid>

          <div
            style={{ position: "absolute", right: "10px" }}
          >
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green" }}
              onClick={saveNetworkInfo}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Network Info updated!
        </MuiAlert>
      </Snackbar>
    </>
  );
}



