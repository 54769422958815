import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import dayjs from "dayjs";

export default function TimeInput({ label='', value, onChange, disabled=false}) {

  const [formattedString, setFormattedString] = useState(null);
  const [timeActual, setTimeActual] = useState(value);
  useEffect(() => { setTimeActual(value)}, [value] );

  const handleTimeString = (event) => {
    const time = stringToDayjs(event.target.value);
    if (time === null) {
      setFormattedString(event.target.value);
    }
    setTimeActual(time);
  }

  const stringToDayjs = (value) => { //convert "hh:mm:ss"/"hh:mm (a|p)m" to dayjs
    if (value.length === 4 && !isNaN(+value)) {
      let str = value;
      let hour = Number(str.substr(0,2));
      let minutes = Number(str.substr(2,2));
      if ((hour >= 0 && hour <=23) && (minutes >= 0 && minutes <= 59)) {
        return (dayjs(new Date().setHours(hour,minutes,0,0)))
      } 
    }

    let str = value.replace(/[: ]/g, "").length === 5 ? '0' + value.replace(/[: ]/g, "") : value.replace(/[: ]/g, "");
    if (str.length === 6) {
      let hour = Number(str.substr(0,2));
      let minutes = Number(str.substr(2,2));
      let amPm = str.substr(4,2).toLowerCase();
      if ((amPm === "am" || amPm === "pm") && (hour >= 0 && hour <=12) && (minutes >= 0 && minutes <= 59)) {
        if (amPm === "pm" && hour < 12) {
          hour += 12;
        } else if (amPm === "am" && hour === 12) {
          hour = 0;
        }
        return (dayjs(new Date().setHours(hour,minutes,0,0)))
      }
    }
    return null // non-valid entry
  }

  const dayjsToString = (value) => { //convert dayjs to "hh:mm (a|p)m"
    if (value && !isNaN(value)) {
      let hour = String(new Date(value).getHours()).length === 1 ? '0' + String(new Date(value).getHours()) : String(new Date(value).getHours());
      let minutes = String(new Date(value).getMinutes()).length === 1 ? '0' + String(new Date(value).getMinutes()): String(new Date(value).getMinutes());
      const time = getFormattedTime(hour + minutes);
      return time;
    }
    return "";
  }

  const getFormattedTime = (fourDigitTime) => {
    var hours24 = parseInt(fourDigitTime.substr(0,2));
    var hours = ((hours24 + 11) % 12) + 1;
    var amPm = hours24 > 11 ? 'pm' : 'am';
    var minutes = fourDigitTime.substring(2);

    return hours + ':' + minutes + " " + amPm;
  };

  useEffect(() => {
    if (timeActual) {
      setFormattedString(dayjsToString(timeActual));
    }
    onChange(timeActual); // sets the time
    // eslint-disable-next-line
  }, [timeActual]);
  
  return (
    <>
      <TextField
        label={label}
        value={formattedString ? formattedString : dayjsToString(timeActual)}
        onChange={handleTimeString}
        disabled={disabled}
      />
    </>
  );
}