import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';
import { Box, Grid, Tabs, Tab } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { ContentBoxWrapper, DocumentBoxWrapper } from "../../styles/theme";
import DocumentList from "../../components/DocumentList/DocumentList";
import ContactList from "../../components/Contacts/ContactsList";
import ActivityFeed from "../../components/Notes/ActivityFeed";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import SiteInfo from "../../components/SiteInfo";
import CurrentDesignDisplay from "../../components/DocumentList/CurrentDesignDisplay";
import { useLocation } from 'react-router-dom';

import { OnboardingTask } from "../../components/ProjectManagement/onboarding/OnboardingTask";
import { SitePrepTask } from "../../components/ProjectManagement/siteprep/SitePrepTask";
import { CoordinationTask } from "../../components/ProjectManagement/coordination/CoordinationTask";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: '10px' }}>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function ProjectManageMainPage() {
    let location = useLocation();
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = new useState(0);
    const [obSubmit, setObSubmit] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (location.state != null) {
            location.state.tab = newValue;
        }
    };

    const [fetchDesignData, setFetchDesignData] = useState(false);
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const handleDesignDataFetch = () => {
        setFetchDesignData(false);
    }

    const gridItemProps = {
        xs: 4,
        container: true,
        justifyContent: "center",
    };

    const handleObSubmit = (state) => {
        setObSubmit(state);
        if (state === false) {
            setFetchDesignData(true);
        }
    }

    const fetchData = () => {
        const endpoint = 'api/Projects/obtask/' + searchParams.get("id");
        setLoading(true);
        axios.get(endpoint)
            .then((response) => {
                setComplete(response.data.statusOb === "Completed");
                setFetchDesignData(true);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error)
                alert("Error retreiving data.");
            });
    }

    useEffect(() => {
        fetchData();

        if (location.state != null) {
            setTabValue(location.state.tab);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <AuthenticatedTemplate>
                {loading ?
                    <Box height="100%" justifyContent="center" alignContent="center">
                        <CircularProgress variant="indeterminate" />
                    </Box>
                    :
                    <>
                        <Grid
                            container
                            rowSpacing={0}
                            style={{ padding: "30px" }}
                        >
                            <Grid item {...gridItemProps}>
                                <ContentBoxWrapper elevation={2} style={{ paddingBottom: "5%" }}>
                                    <SiteInfo />
                                </ContentBoxWrapper>
                            </Grid>

                            <Grid item {...gridItemProps}>
                                <ContentBoxWrapper elevation={2} style={{ paddingBottom: "5%" }}>
                                    <ActivityFeed projectId={searchParams.get("id")} />
                                </ContentBoxWrapper>
                            </Grid>

                            <Grid item {...gridItemProps}>
                                <ContentBoxWrapper elevation={2} style={{ paddingBottom: "5%" }}>
                                    <ContactList />
                                </ContentBoxWrapper>
                            </Grid>

                            <Grid item container xs={6}>
                                <ContentBoxWrapper elevation={2} sx={{ minHeight: "100%" }}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
                                            <Tabs value={tabValue} onChange={handleTabChange}>
                                                <Tab label="Onboarding" {...tabProps(0)} />
                                                <Tab label="Site Prep" {...tabProps(1)} disabled={!complete}/>
                                                <Tab label="Coordination" {...tabProps(2)} disabled={!complete}/>
                                            </Tabs>
                                        </div>
                                    </Box>
                                    <CustomTabPanel value={tabValue} index={0}>
                                        <OnboardingTask onObTaskSubmit={handleObSubmit} />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={tabValue} index={1}>
                                        <SitePrepTask />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={tabValue} index={2}>
                                        <CoordinationTask />
                                    </CustomTabPanel>
                                </ContentBoxWrapper>
                            </Grid>

                            <Grid item xs={6}>
                                <Grid item container xs={12}>
                                    <DocumentBoxWrapper elevation={2} sx={{ minWidth: "100%" }}>
                                        <DocumentList 
                                            prelimAdded={fetchData} 
                                            onObTaskSubmit={handleObSubmit} 
                                            obSubmit={obSubmit}
                                        />
                                    </DocumentBoxWrapper>
                                </Grid>

                                <Grid container xs={12}>
                                    <CurrentDesignDisplay
                                        projectId={searchParams.get("id")}
                                        fetchDesignData={fetchDesignData}
                                        onDataFetched={handleDesignDataFetch}
                                        obSubmit={obSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }

            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Typography variant="h6">
                    <center>Please sign-in to see your profile information.</center>
                </Typography>
            </UnauthenticatedTemplate>
        </>
    );
}
