import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";

export default function DeleteHoliday({ holiday, handleClose, updateHolidays }) {

  const handleDelete = () => {
    const endpoint = `/api/TimeSheet/deleteHoliday/${holiday.id}`;

    axios
      .delete(endpoint)
      .then(() => {
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data) {
            alert(error.response.data);
            handleClose();
        } else {
            alert("Error deleting holiday. Please try again.");
            handleClose();
        }
      })
      .finally(() => {
        updateHolidays();
        handleClose();
      })
  };

  return (
    <div>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Delete Holiday?</DialogTitle>
        <DialogContent>
          Holiday hours for this holiday will be removed from ALL employees.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}