import React, { useState, useEffect } from "react";
import { Grid, Fade, Modal, Button} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import LoadPTO from "./LoadPTO";

export default function PTODetailsTable() {

  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [employee, setEmployee] = useState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOpenLoadModal = (employee) => {
    setEmployee(employee);
    setIsLoadModalOpen(true);
  }

  const handleCloseLoadModal = () => {
    setIsLoadModalOpen(false);
    fetchData();
  }

  const fetchData = () => {
    setLoading(true);
    const endpoint = 'api/Employee/employee';
    axios.get(endpoint)
      .then((response) => {
        loadRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, []);   

  const loadRows = (employees) => {

    const nullToZero = (val) => {
      if (val) {
        return val
      } else {
        return 0
      }
    }

    let newRows = [];
    if (employees.length > 0) {
      employees.forEach(e => {
        const offsetDate = (date) => {
          return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
        }
        newRows.push({
          id: e.userId,
          firstName: e.firstName,
          lastName: e.lastName,
          department: e.department,
          totalPto: e.ptoTotal ? e.ptoTotal : "",
          availablePto: e.ptoRemaining,
          pendingPto: e.ptoPending ? e.ptoPending : "",
          usedPto: e.ptoUsed ? e.ptoUsed : "",
          rollOverPto: e.rollOverPto ? e.rollOverPto : "",
          rollOverDate: e.rollOverDate ? offsetDate(new Date(e.rollOverDate)).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }) : "",
        })
      })
    }
    setRows(newRows);
  }

  const renderEditButton = (params) => {
      return (
        <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
                handleOpenLoadModal(params.row)
            }}
        >
            Load PTO
        </Button>
      )
  }

  const columns = [
    {
      width: 100,
      renderCell: renderEditButton,
      disableClickEventBubbling: true,
    },
    { field: "id", headerName: "ID", width: 25 },
    { field: "firstName", headerName: "First Name", width: 100 },
    { field: "lastName", headerName: "Last Name", width: 100 },
    { field: "department", headerName: "Department", width: 100 },
    { field: "totalPto", headerName: "PTO Max", width: 100 },
    { field: "availablePto", headerName: "Remaining PTO", width: 150 },
    { field: "pendingPto", headerName: "Pending PTO", width: 125 },
    { field: "usedPto", headerName: "Used PTO", width: 100 },
    { field: "rollOverPto", headerName: "Rollover PTO", width: 125 },
    { field: "rollOverDate", headerName: "Rollover Expiration", width: 150 },
  ];

  return (
    <Grid>
      <DataGrid onRowDoubleClick={(params) => handleOpenLoadModal(params.row)} rows={rows} columns={columns} 
        initialState={{ pagination: { paginationModel: { pageSize: 25 } }, columns: {columnVisibilityModel: {}} } }
        pageSizeOptions={[10, 25, 100]}
        disableRowSelectionOnClick
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
          },
          "& .MuiDataGrid-row:hover": {
              cursor: "pointer"
          },
          overflowY: 'scroll', 
          height: 490,
          width: "100"
        }}
        state={{
          showSkeletons: loading,
        }}
      />
      <Modal
          open={isLoadModalOpen}
          onClose={handleCloseLoadModal}
          closeAfterTransition
        >
          <Fade in={isLoadModalOpen}>
            <div>
              <LoadPTO employee={employee} onUpdatePto={handleCloseLoadModal}/>
            </div>
          </Fade>
        </Modal>
    </Grid>
  );
};