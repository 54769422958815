import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Box, Grid, Typography } from "@mui/material";
import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import TopDeviceSection from "../../components/DeviceInfo/TopSection/TopDeviceSection";
import MidDeviceSection from "../../components/DeviceInfo/MidSection/MidDeviceSection";
import BtmDeviceSection from "../../components/DeviceInfo/BtmSection/BtmDeviceSection";
import { systemsIntegrationAxios as axios } from "../../utils/AxiosUtility";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: '10px' }}>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


export function MasterInfo() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pcId = queryParams.get('productConfigId'); 
    const prodType = queryParams.get('prodType'); 

    const [allDevices, setAllDevices] = useState();
    const [productConfigId, setProductConfigId] = useState(pcId); 
    const [productType, setProductType] = useState(prodType);
    const [unitData, setUnitData] = useState();

    useEffect(() => {
        const fetchDeviceInfo = async () => {
            if (productConfigId) {
                try {
                    const deviceInfoEndpoint = `api/DeviceInfo/getDeviceInfoFromPcId/${productConfigId}`;
                    axios.get(deviceInfoEndpoint)
                        .then((response) => {
                            setAllDevices(Array.isArray(response.data) ? response.data : [response.data]);
                        });
                }
                catch (error) {
                    console.error("Error retrieving all devices: ", error);
                    throw error;
                }

                try {
                    const pcEndpoint = `api/ProductConfiguration/productConfig/${productConfigId}`;
                    const response = await axios.get(pcEndpoint);
                  }
                  catch (error) {
                    console.error(`Error fetching the config info: ${error}`);
                  }
            }
        };

        fetchDeviceInfo();
    }, [productConfigId]);

    useEffect(() => {
        if (productConfigId !== pcId) {
            setProductConfigId(pcId);
        }

        if (productType !== prodType) {
            setProductType(prodType);
        }
    }, [pcId, prodType, productConfigId, productType]);

    return (
        <>
            <AuthenticatedTemplate>
                <Grid container rowSpacing={2} style={{ padding: "30px" }}>
                    <TopDeviceSection prodConfigId={productConfigId} prodType={productType} />
                    <MidDeviceSection prodConfigId={productConfigId} prodType={productType} />
                    <BtmDeviceSection prodConfigId={productConfigId} prodType={productType} unit={unitData} />
                </Grid>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Typography variant="h6">
                    <center>Please sign in to see your profile information.</center>
                </Typography>
            </UnauthenticatedTemplate>
        </>
    );
}

