import React, { useState, useEffect } from "react";
import { Grid, Stack, Fade, Modal, Button, Dialog, DialogTitle, DialogContent, DialogActions} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs";
import EditTimeSheetLog from "./EditTimeSheetLog";
import DeleteTimeSheetLog from "./DeleteTimeSheetLog";

export default function TimeSheetDetails(logs) {

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [timeLog, setTimeLog] = useState();
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const dialogTitle = "Meal Override Reason:";
  const [dialogText, setDialogText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpenEditModal = (log) => {
    if (logs.status === "Not Submitted") {
      setTimeLog(log);
      setIsEditModalOpen(true);
    }
  }

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    fetchData();
  }

  const handleOpenDeleteModal = (log) => {
    if (logs.status === "Not Submitted") {
      setTimeLog(log);
      setIsDeleteModalOpen(true);
    }
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    fetchData();
  }

  const handleOpenDialog = (params) => {
    setDialogText(params.lunchOverrideReason);
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const formatHours = (hours) => {
    let hour = parseInt(hours);
    let minute = ((hours - hour) * 60).toFixed(0);
    let formatedString = hour.toString() + " hr ";
    if (minute > 0) {
      formatedString += minute.toString() + " min ";
    }
    return formatedString;
  }

  const handleCalcHours = (clockInTime, clockOutTime) => {
    if (clockInTime && clockOutTime) {
        return (Number(((hmsToSeconds(clockOutTime) - hmsToSeconds(clockInTime)) / 3600).toFixed(2)));
    }
    return (0);
  }

  function hmsToSeconds(s) {
    var b = s.split(':');
    return b[0]*3600 + b[1]*60 + (+b[2] || 0);
  }

  const fetchData = () => {
    setLoading(true);
    const endpoint = 'api/TimeSheet/timeSheet';
    axios.get(endpoint)
      .then((response) => {
        loadRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [logs.listener]);   

  const loadRows = (timeSheet) => {

    let newRows = [];
    if (timeSheet.length > 0) {
      timeSheet.forEach(tl => {
        const offsetDate = (date) => {
          return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
        }
        const tConvert = (time) => {
          const i = tl.timesheetDate.split("/");
          const t = new Date(i[0]+'T' + time + 'Z');
          return t;
        }
        if ((tl.empUserId === logs.employeeId && dayjs(logs.weekStart) <= dayjs(tl.timesheetDate) 
            && dayjs(logs.weekEnd) >= dayjs(tl.timesheetDate))) {
          newRows.push({
            id: tl.timesheetId,
            date: tl.timesheetDate? offsetDate(new Date(tl.timesheetDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }) : "",
            day: tl.tsDay ? tl.tsDay : "",
            userID: tl.empUserId,
            clockIn: tl.clockIn ? tConvert(tl.clockIn).toLocaleString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            }) : "",
            clockOut: tl.clockOut ? tConvert(tl.clockOut).toLocaleString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            }) : "",
            hourType: tl.hourType,
            totalHours: tl.hoursWorked ? 
                          (tl.clockIn && tl.clockOut ? 
                            (handleCalcHours(tl.clockIn, tl.clockOut) < 0 ? 
                            formatHours(handleCalcHours(tl.clockIn, tl.clockOut) + 24) 
                            : formatHours(handleCalcHours(tl.clockIn, tl.clockOut))) 
                          : formatHours(tl.hoursWorked))
                        : "0 hr",
            hours: tl.hoursWorked ? formatHours(tl.hoursWorked) : "0 hr",
            lunchOverride: tl.lunchOverride,
            description: tl.tsDescription && tl.tsDescription !== "-" ? tl.tsDescription : "",
            lunchOverrideReason: tl.lunchOverrideReason,
          })
        }
      })
    }
    setRows(newRows);
  }

  const renderOverrideIcon = (params) => {
    return (
      <strong>
        {params.row.lunchOverride
        ? (
          <Button
            onClick={() => {
              handleOpenDialog(params.row)
            }}
          >
            <CheckCircleIcon style={{ color: "green" }} />
          </Button>
        ) : (
          null
        )}
       
      </strong>
    )
  }

  const renderEditButton = (params) => {
      return (
          <strong>
              {params.row.hourType === "Regular Work" 
              ? (
                <Stack flexDirection={"row"} justifyContent={"space-between"} paddingTop={1.25}>
                  <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={logs.status === "Approved" || logs.status === "Pending Approval"}
                      onClick={() => {
                          handleOpenEditModal(params.row)
                      }}
                  >
                      Edit
                  </Button>
                  <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={logs.status === "Approved" || logs.status === "Pending Approval"}
                      onClick={() => {
                          handleOpenDeleteModal(params.row)
                      }}
                  >
                      Delete
                  </Button>
                </Stack>
                
              ) : (null)}
          </strong>
      )
  }

  const columns = [
    { field: "date", headerName: "Date", width: 100 },
    { field: "day", headerName: "Day", width: 100 },
    { field: "clockIn", headerName: "Clock In", width: 100 },
    { field: "clockOut", headerName: "Clock Out", width: 100 },
    { field: "hourType", headerName: "Hour Type", width: 120 },
    { field: "totalHours", headerName: "Total Hours", width: 120 },
    { field: "hours", headerName: "Worked Hours", width: 120 },
    {
      field: "lunchOverride",
      headerName: "Meal Override", 
      width: 110,
      renderCell: renderOverrideIcon
    },
    { field: "description", headerName: "Description", width: 170 },
    {
      width: 165,
      renderCell: renderEditButton,
      disableClickEventBubbling: true,
    }
  ];

  return (
    <Grid>
      <DataGrid onRowDoubleClick={(params) => handleOpenEditModal(params.row)} rows={rows} columns={columns} 
        initialState={{ pagination: { paginationModel: { pageSize: 25 } }, columns: {columnVisibilityModel: {}} } }
        pageSizeOptions={[10, 25, 100]}
        disableRowSelectionOnClick
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
          },
          "& .MuiDataGrid-row:hover": {
              cursor: "pointer"
          },
          overflowY: 'scroll', 
          height: 490,
          width: "76vw"
        }}
        state={{
          showSkeletons: loading,
        }}
      />
      <Modal
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        closeAfterTransition
      >
        <Fade in={isEditModalOpen}>
          <div>
            <EditTimeSheetLog timeLog={timeLog} onUpdateTimeSheetLog={handleCloseEditModal} updateSheet={logs.updateSheet}/>
          </div>
        </Fade>
      </Modal>
      <Modal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        closeAfterTransition
      >
        <Fade in={isDeleteModalOpen}>
          <div>
            <DeleteTimeSheetLog timeLog={timeLog} onTimeSheetLogDeleted={handleCloseDeleteModal} updateSheet={logs.updateSheet}/>
          </div>
        </Fade>
      </Modal>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              {dialogText}
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                  Close
              </Button>
          </DialogActions>
      </Dialog>
    </Grid>
  );
};